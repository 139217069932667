import { MenuItem } from './menu.model';

export const MENU: MenuItem[] = [
    {
        id: 1,
        label: 'MENUITEMS.DASHBOARD.TEXT',
        icon: 'Dashboard.png',
        link: '/dashboard',
    },

    {
        id: 2,
        label: 'MENUITEMS.MGVENUE.TEXT',
        icon: 'Manage Venue.png',
        link: '/manage-venue',
    },
    {
        id: 3,
        label: 'MENUITEMS.MGPROMOTER.TEXT',
        icon: 'Manage Promoters.png',
        link: '/manage-promoters',
        
    },
    {
        id: 4,
        label: 'MENUITEMS.MGUSER.TEXT',
        icon: 'Manage Users.png',
        link: '/manage-user',
    },
    {
        id: 4,
        label: 'MENUITEMS.MBROADCAST.TEXT',
        icon: 'Manage Broadcast.png',
        link: '/manage-broadcast',
    },
    {
        id: 5,
        label: 'MENUITEMS.SUPPORT.TEXT',
        icon: 'Support Queries.png',
        link: '/support-queries'
    },
    {
        id: 6,
        label: 'MENUITEMS.OFFERS.TEXT',
        icon: 'Offers.png',
        link: '/offers'
    }
];

